img {
    display: block;
    width: 100%;
    height: auto;
}

figure.rounded {
    border-radius: 50%;
    display: block;
    overflow: hidden;
    transform: translate(0px, 0px);

    img {
        border-radius: 50%;
        width: 250px;
        height: 250px;
        transition: transform 0.15s;
        object-fit: cover;
        &:hover {
            .teaser--link & {
                transform: scale(1.05);
            }
        }
    }
}
