.icon-arrow-bottom:before {
    font-icon: url('../icons/arrow-bottom.svg');
}
.icon-arrow-left:before {
    font-icon: url('../icons/arrow-left.svg');
}
.icon-arrow-right:before {
    font-icon: url('../icons/arrow-right.svg');
}
.icon-arrow-top:before {
    font-icon: url('../icons/arrow-top.svg');
}
.icon-email:before {
    font-icon: url('../icons/email.svg');
}
.icon-facebook:before {
    font-icon: url('../icons/facebook.svg');
}
.icon-twitter:before {
    font-icon: url('../icons/twitter.svg');
}
