/* Typography */
/* montserrat-300 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../fonts/montserrat-v25-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-300.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-regular.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../fonts/montserrat-v25-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-500.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$sans: 'Montserrat', sans-serif;
$sans2: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: Georgia, serif;
$mono: Menlo, Courier, monospace;

@mixin pagetitle {
    font-family: $sans;
    font-weight: 300;
    font-size: 35px;
    line-height: 1.2;

    @include respond-to('m') {
        font-size: 60px;
    }
}

@mixin title {
    font-family: $sans;
    font-weight: 300;
    font-size: 28px;
    line-height: 1.2;

    @include respond-to('m') {
        font-size: 36px;
    }
}

@mixin headline {
    font-family: $sans;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;

    @include respond-to('m') {
        font-size: 20px;
    }
}

@mixin text {
    font-family: $sans;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;

    @include respond-to('m') {
        font-size: 16px;
    }
}
@mixin text--small {
    font-family: $sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.6;

    @include respond-to('m') {
        font-size: 14px;
    }
}

@mixin meta {
    font-family: $sans;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.6;
    letter-spacing: -1.5%;
}

@mixin navigation {
    font-family: $sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    @include respond-to('m') {
        font-size: 18px;
    }
}

@mixin navigation-small {
    font-family: $sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: -0.05rem;

    @include respond-to('m') {
        font-size: 18px;
    }
}

@mixin dark-on-light {
    -webkit-font-smoothing: antialiased;
}

.dark-on-light {
    @include dark-on-light;
}

@mixin font-light {
    font-weight: 300;
}

@mixin font-regular {
    font-weight: 400;
}

@mixin font-bold {
    font-weight: 500;
}

@mixin slider-indicator {
    font-family: $sans;
    font-weight: 400;
    font-size: 48px;
    line-height: 1;

    @include respond-to('m') {
        font-size: 56px;
        line-height: 1;
    }
    @include respond-to('l') {
        font-size: 64px;
        line-height: 1;
    }
}

address {
    font-style: normal;
    a {
        color: black;
        &:hover {
            color: $blue-1;
            text-decoration: none;
        }
    }
}

.inline {
    display: inline;
}

.underline {
    text-decoration: underline;
}

.inline.underline {
    margin-right: 1rem;
}

.secondary {
    color: var(--color-sub);
}

.centered {
    margin: 0 auto;
}

.text-centered {
    text-align: center;
    margin: 0 auto;
}

.reader {
    max-width: 720px;
}

h1,
.pagetitle {
    @include pagetitle;
}

h2,
.title {
    @include title;
}

h3,
.headline {
    @include headline;
}

h4 {
    @include headline;
}

h5 {
    @include headline;
}

h6 {
    @include headline;
}

p,
.text {
    @include text;
    max-width: 42ch;
    hyphens: auto;

    &.text--secondary {
        color: $c_typo_secondary;
    }
}
p,
.text--small {
    @include text--small;
    max-width: 42ch;
    hyphens: auto;

    &.text--secondary {
        color: $c_typo_secondary;
    }
}

ul,
ol {
    @include text;
}

table {
    width: 100%;
    margin: 1em 0 1.75em;
    @include text;

    th,
    td {
        padding: 1em 1em 1em 0;
        border-bottom: 1px solid var(--color-border);
    }

    th {
        border-bottom-width: 2px;
        font-weight: 500;
    }
}

blockquote {
    display: block;
    margin: 1em 0 1.25em 0;
    @include title;
}

ul {
    margin: 1.25em 0 1.75em 1em;
    list-style-type: disc;

    li {
        margin-left: 0.5em;
        margin-bottom: 0.5rem;
    }
}

ol {
    margin: 1.25em 0 1.75em 1em;
    list-style-type: decimal;

    li {
        margin-left: 0.5em;
        margin-bottom: 0.5rem;
    }
}

a {
    @include text;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    & + * {
        margin-top: 0.25em;
    }

    a {
        text-decoration: none;
    }
}

* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
    margin-top: 1.25em;
}

p {
    & + p {
        margin-top: 1.25em;
    }
}
