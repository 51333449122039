.btn {
    @include navigation;
    display: block;
    position: relative;
    padding: 7px 7px;

    @include respond-to('s') {
        padding: 7px 1.5rem;
    }

    text-decoration: none;
    border-radius: 4px;
    border: 0;
    -webkit-appearance: none;
    color: $brand-black;
    background-color: $yellow;

    &:hover {
        background-color: $yellow-light;
        text-decoration: none;
        color: #303030;
    }
}

.bg--black {
    .btn--secondary {
        color: $white;
    }
}
