/* Home */
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        margin-top: 10px;
    }

    img {
        margin: 0 auto;
        max-width: 223px;

        @include respond-to('m') {
            max-width: 445px;
        }
    }

    height: 100vh;
    max-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    color: $white;
}

.frag-sonja {
    padding: 50px 20px;

    .container--limited {
        @include respond-to('m') {
            display: flex;
            justify-content: space-between;
        }
    }

    &__title {
        margin-bottom: 20px;
        color: $blue-1;
    }
    p.margin-top {
        margin-top: 20px;
    }
    p.full {
        max-width: 100%;
    }
    &__text {
        &,
        p {
            @include text;
        }
        flex: 0 0 70%;

        svg {
            display: inline;
            height: 1.5em;
            width: 1.5em;
            vertical-align: middle;
            margin-right: 0.5em;
            fill: $blue-2;
        }

        .info {
            @include headline;
        }
    }

    &__image {
        margin-top: 32px;
        @include respond-to('s') {
            margin-top: 0;
        }

        svg {
            display: block;
            margin-left: auto;
        }
    }

    &--blue {
        background-color: $light-blue;
    }

    &--special {
        padding: 70px 24px;
        background-color: $blue-1;
        position: relative;
        color: $white;
        z-index: 1;
        @include respond-to('m') {
            padding: 120px 40px;
        }

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: radial-gradient(
                193.59% 448.22% at 15.97% 15.67%,
                rgba(242, 250, 253, 0.4) 0%,
                rgba(0, 159, 227, 0.4) 100%
            );
            opacity: 0.8;
            z-index: 1;
            pointer-events: none;
        }

        & > .container {
            z-index: 2;
            position: relative;
            text-align: center;
        }

        h2 {
            @include title;
        }

        h3 {
            @include headline;
        }

        h2,
        h3 {
            text-align: center;
        }

        button,
        a {
            display: inline;
        }
    }

    address {
        p {
            @include text;
        }
        @include respond-to('m') {
            display: flex;
            justify-content: space-between;
        }
    }

    .wrapper {
        @include respond-to('m') {
            display: flex;
            justify-content: space-between;
        }
    }

    &-merkliste {
        margin: 40px auto;
        max-width: 620px;

        &__entry {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $white;
            border-bottom: 1px solid $blue-2;
            flex: 1 1 auto;
            p {
                text-align: left;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        &__counter {
            display: flex;
            flex: 0 0 auto;
            justify-content: space-around;
            align-items: center;

            span {
                @include title;
                padding: 0 10px;
            }
        }

        &__empty {
            @include text--small;
            text-align: center;
            color: $blue-2;
            margin: 20px auto 60px auto;
        }
    }
}

.product {
    position: relative;
    width: 248px;
    height: 400px;

    @include respond-to('s') {
        width: 310px;
        height: 500px;
    }
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    margin-right: 10px;
    overflow: hidden;

    &__front,
    &__back {
        background-color: $white;
        padding: 30px 10px 10px 10px;
        width: 100%;
        height: 100%;
        transition: 0.5s ease-in;
    }

    &__front {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
    }

    &__heart {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 101;

        &--selected {
            svg {
                fill: $blue-1;
            }
        }
    }

    &__image-container {
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 1 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &__back {
        &:before {
            z-index: 998;
            background: radial-gradient(
                193.59% 448.22% at 15.97% 15.67%,
                rgba(242, 250, 253, 0.4) 0%,
                rgba(0, 159, 227, 0.4) 100%
            );
            opacity: 0.8;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            mix-blend-mode: soft-light;
        }

        background: $blue-1;
        position: absolute;
        height: 50%;
        top: 0;
        left: 0;
        color: $white;
        transform: translateY(200%);
        transition: 0.5s ease-in;
        z-index: 997;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
            margin: 10px auto 5px auto;
            z-index: 999;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__title {
        flex: 0 0 auto;
        height: 50px;
        transition: 0.5s ease-in;
        @include navigation;
        margin-bottom: 8px;
        padding: 0 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
    }
    &__sub-title {
        flex: 0 0 auto;
        transition: 0.5s ease-in;
        @include navigation;
        margin-bottom: 8px;
    }
    &__description {
        @include text--small;
        flex: 1 1 auto;
        line-height: 1.1;
    }
    &__price {
        @include text--small;
        font-weight: bold;
        display: block;
        flex: 0 0 auto;
        margin-top: auto;
        margin-bottom: 10px;
        &:before {
            content: 'Preis: ';
        }
    }
    &__image {
        flex: 0 1 auto;
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    &__label {
        @include navigation;
        flex: 0 0 auto;
    }
}

.product:hover {
    .product__front .product__title,
    .product__front .product__subTitle {
        opacity: 0;
    }
    .product__back {
        transform: translateY(100%);
    }
}

.product.product--overview {
    background-color: $blue-1;
    justify-content: center;
    align-items: center;
    padding: 30px 10px 20px 10px;

    .title {
        color: $white;
        text-align: center;
    }

    .icon {
        width: 105px;
        height: 105px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        @include pagetitle;
    }

    footer {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: $blue-2;
        font-size: 18px;
        bottom: 20px;

        img {
            max-height: 35px;
            width: auto;
            margin-right: 8px;
        }
    }
}

.hide {
    display: none;
}
