/* Base */

*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: none;
    /*auto-zoom auf iphone ausschalten*/
    -ms-text-size-adjust: none;
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    width: 100%;
    overflow-x: hidden;
    vertical-align: baseline;
    font-family: $sans;
}

body {
    position: relative;
    letter-spacing: 0.025em;
    background-color: $white;
    color: $c_typo_primary;
}
section[id]::before {
    content: '';
    display: block;
    height: 150px;
    margin-top: -150px;
    visibility: hidden;
}
