nav {
    ul {
        margin: 0 auto;
        li {
            margin: 0;
            a {
                color: $black;
                text-decoration: none;
                display: block;
                padding: 0.65rem 1rem;
                transition: color 0.15s ease-in-out;

                @include navigation;

                &:hover,
                &:focus,
                &:active,
                &.active {
                    color: $black;
                }

                &.link--image {
                    padding: 0 1rem;
                }
            }
        }

        &.fixed {
            @include respond-to('m') {
                position: fixed;
                z-index: 100;
                transition: opacity 2s ease-in-out;
            }

            &--right {
                @include respond-to('m') {
                    top: 85%;
                    transform: translateY(-80%);
                    right: 3rem;
                }
            }
            &--right-top {
                @include respond-to('m') {
                    position: absolute;
                    top: 4rem;
                    transform: translateY(-10%);
                    right: 3rem;
                }
            }
        }
    }

    &.inline {
        //padding: 4rem 0;
        ul {
            display: flex;
            align-items: center;
            li {
                display: block;
            }
        }
        &--center {
            ul {
                justify-content: center;
            }
        }
        &--right {
            margin: 0;
            @include respond-to('m') {
                margin-left: auto;
            }

            ul {
                justify-content: flex-start;
                @include respond-to('m') {
                    justify-content: flex-end;
                }
            }
        }
    }

    &.topmenu {
        background-color: $brand-1;
        position: absolute;
        z-index: 100;
        width: 100vw;
        @include respond-to('m') {
            display: flex;
            background-color: transparent;
        }

        ul {
            li {
                display: none;
                @include respond-to('m') {
                    display: list-item;
                }
            }

            &.language {
                li {
                    @include respond-to('m') {
                        display: inline-block;
                    }

                    a {
                        color: $white;
                        @include font-light;
                    }
                }
            }
            &.menu,
            &.language {
                padding-left: 2rem;
                @include respond-to('m') {
                    padding-left: 0;
                }
            }
        }

        a {
            display: block;

            &.logo picture img {
                padding: 1.5rem 2rem;
                max-width: 260px;
                @include respond-to('m') {
                    padding: 3.5rem 0 0 5rem;
                    max-width: 90%;
                }
            }

            &.icon {
                color: $white;
                display: block;
                position: fixed;
                top: 2.5rem;
                right: 2rem;
                @include respond-to('m') {
                    display: none;
                    top: 2rem;
                }
            }
        }

        &.responsive {
            ul {
                li {
                    display: block;
                    @include respond-to('m') {
                        display: list-item;
                    }
                }
                &.language li {
                    display: inline-block;
                }
            }
        }
    }
}

/* Add a black background color to the top navigation */
#header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    .navbar__brand {
        display: block;
        @include respond-to('m') {
            padding: 0;
        }

        img {
            width: 140px;
        }
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 1rem;
    background-color: $brand-1;
    min-height: 74px;
    @include respond-to('m') {
        padding: 0 90px;
    }

    &__brand {
        padding: 0.5rem;
        width: auto;
        opacity: 1;
        img {
            display: block;
            width: 200px;
            height: auto;
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 0 auto;

        @include respond-to('m') {
            flex-direction: row;
            align-items: center;
        }

        footer & {
            flex-direction: row;
            align-items: center;
        }

        &-item {
            width: 100%;

            @include respond-to('m') {
                width: auto;
            }
        }

        &-link {
            display: block;
            color: $gray-2;
            text-align: center;
            margin: 0 0.5rem;
            padding: 1rem;
            text-decoration: none;
            background: transparent;
            @include navigation;

            footer & {
                @include navigation-small;
            }

            @include respond-to('m') {
                display: block;
                margin: 0;
            }

            &:hover {
                color: $gray-1;
            }

            &.active {
                color: $c-bg_secondary;
            }
        }
    }
}

.heart {
    list-style-type: none;
    padding: 15px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;

    &__item {
        display: flex;

        &:first-child {
            a {
                display: flex;
                padding: 0;
            }
            margin-right: 10px;
            @include respond-to('m') {
                margin-right: 15px;
            }
            @include respond-to('m') {
                margin-right: 30px;
            }
        }
    }

    &__link {
        display: block;
        width: 100%;
        text-align: center;
        color: $gray-2;
        border: 1px solid $gray-3;
        padding: 1rem 0;

        @include respond-to('m') {
            border: none;
        }

        &:hover,
        &.active {
            color: $gray-1;
            background: $gray-3;

            @include respond-to('m') {
                background: transparent;
            }
        }
    }
}

.navbar--hide {
    background: transparent;
    .navbar__brand {
        opacity: 0;
    }
}
