.catalogue {
    display: flex;
    //border: 1px solid #2c302d;
    //background: #131513;
    position: relative;
    margin: 0 0 25px;

    &__container {
        min-height: 410px;
    }

    &__category {
        flex: 0 0 310px;

        position: relative;
        z-index: 1;
        padding: 0;
    }

    &__content {
        flex: 1 1 auto;
        overflow-x: auto;
        position: relative;
        z-index: 1;
        user-select: none;
        cursor: pointer;
        &--active {
            cursor: grabbing;
            cursor: -webkit-grabbing;
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }

        &::-webkit-scrollbar-thumb {
            background: $blue-2;
            border: 0 none #ffffff;
            border-radius: 0;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #666666;
        }

        &::-webkit-scrollbar-thumb:active {
            background: #666666;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0 none #ffffff;
            border-radius: 50px;
        }

        &::-webkit-scrollbar-track:hover {
            background: #ffffff;
        }

        &::-webkit-scrollbar-track:active {
            background: #ffffff;
        }

        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
    &__content-inner {
    }
    &__nav {
        position: absolute;
        height: 100%;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        &--prev {
            top: 0;
            left: 0;
        }
        &--next {
            top: 0;
            right: 0;
        }
    }
    &__products {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        min-height: 410px;
        padding-right: 20px;
        width: max-content;
        @include respond-to('s') {
            min-height: 510px;
        }
    }
}

.catalogue__category {
    display: none;
    .product--overview {
        display: none;
    }
    @include respond-to('s') {
        display: block;
        .product--overview {
            display: flex;
        }
    }
}
.catalogue__products {
    @include respond-to('s') {
        .product--overview {
            display: none;
        }
    }
}
