/* Variables */

/* Colors */
$black: #000;
$white: #fff;

$gray-1: #707070;
$gray-2: #c0c0c0;
$gray-3: #f0f0f0;

$blue-1: rgba(0, 159, 227, 1);
$blue-2: rgba(84, 204, 255, 1);
$light-blue: rgba(242, 250, 253, 1);
$yellow: rgba(255, 219, 13, 1);
$yellow-light: rgba(255, 234, 111, 1);
$brand-black: rgba(48, 48, 48, 1);

$brand-1: $blue-1;

$product-1: $brand-1;
$product-2: #08d642;
$product-3: #0b79df;
$product-4: #f5e33f;
$product-5: #ffb905;

/* Element-Colors */
$c_typo_primary: $black;
$c_typo_secondary: $gray-1;

$c_bg_primary: $white;
$c_bg_secondary: $gray-3;

$c_typo_invers: $white;
$c_bg_invers: $black;
$c_bg_hero: $black;
/* Breakpoints */
$breakpoints: (
    's': 640px,
    'm': 960px,
    'l': 1280px,
    'xl': 1680px
);
/* Z-Indexes */
$z-indexes: (
    'modal': 5000,
    'dropdown': 4000,
    'default': 1,
    'below': -1
);

:root {
    --color-main: #000;
    --color-secondary: #0aa244;
    --color-tertiary: #fff;
    --font-rendering: subpixel-antialiased;
}
