.section {
    position: relative;
    padding: 4rem 1rem;

    @include respond-to('m') {
        padding: 8rem 1rem;
    }

    @include respond-to('l') {
        // padding: 8rem 3rem;
    }

    [class^='block--'],
    [class*=' block--'] {
        & + [class^='block--'],
        & + [class*=' block--'] {
            margin-top: 3rem;

            @include respond-to('m') {
                margin-top: 6rem;
            }
        }
    }

    .container {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 100%;

        &--limited {
            position: relative;
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 1140px;

            &-small {
                max-width: 960px;
            }

            &-large {
                max-width: 1400px;
            }
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include respond-to('l') {
                max-width: 1440px;
                right: 0;
                left: auto;
            }

            figure {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background-image: url("../img/products/sonderwerkzeuge_cat.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: right center;
                opacity: 0.1;
                @include respond-to('m') {
                    opacity: 0.4;
                }
            }
        }
    }

    &--bgimage {
        min-height: 400px;
        @include respond-to('s') {
            min-height: 500px;
        }
        @include respond-to('m') {
            min-height: 600px;
        }
        @include respond-to('l') {
            min-height: 800px;
        }

        .inner {
            @include respond-to('s') {
                padding-right: 35%;
            }
            @include respond-to('m') {
                padding-right: 50%;
            }
            @include respond-to('l') {
                padding-right: 500px;
            }
        }
    }

    &--full {
        min-height: 100vh;
    }

    &.padding--large {
        .inner {
            padding: 2rem 1rem;

            @include respond-to('m') {
                padding: 6rem 9rem;
            }
        }
    }
}

.section--colored {
    background-color: $c-bg_secondary;
    color: $white;
}

.section--min {
    min-height: 60vh;
}

.inner {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 960px;

    &--small {
        max-width: 720px;
    }

    &--large {
        max-width: 1200px;
    }
}

.section.section--bg {
    /*
  background: url(../img/products/sonderwerkzeuge_cat.png) no-repeat center center;
  */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .container {
        z-index: 3;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // background: rgba(0,0,0,1);
        z-index: 0;
        transition: background 0.15s ease-in-out;
    }

    &.show:after {
        // background: linear-gradient(90deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    }
}
