footer {
    background-color: transparent;
    margin-top: 16px;
    .meta {
        background-color: $blue-1;
        color: $white;
        display: flex;
        align-items: center;
        flex-direction: column;
        line-height: 1.5;
        min-height: 65px;

        @include respond-to('m') {
            flex-direction: row;
            justify-content: space-between;
            padding-right: 40px;
        }

        .nav {
            order: -1;
            @include respond-to('m') {
                order: inherit;
                padding-bottom: 0;
            }
            &--list {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                @include respond-to('m') {
                    justify-content: flex-end;
                }
            }

            &__item {
                a {
                    padding: 0.5rem;
                    @include navigation-small;
                    color: $white;

                    @include respond-to('m') {
                        padding: 1rem;
                    }

                    &:hover {
                        color: $blue-2;
                        text-decoration: none;
                    }
                }

                & + .nav--list__item {
                    @include respond-to('m') {
                        margin-left: 2rem;
                    }
                }
            }
        }
    }
}

.brand--footer {
    width: 174px;
    height: auto;
}
