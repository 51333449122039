#scrollToTop {
    display: none; /* Hidden by default */
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Fixed/sticky position */
    width: 3rem;
    height: 3rem;
    bottom: 1.5rem; /* Place the button at the bottom of the page */
    right: 1.5rem; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: $gray-3; /* Set a background color */
    color: $gray-2; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 0.5rem 1.25rem; /* Some padding */
    border-radius: 50%; /* Rounded corners */
    font-size: 16px; /* Increase font size */
    transition: all 0.25s;
    &:before {
        font-family: 'icons';
        color: $gray-2;
        @extend .icon-arrow-top;
    }
}

#scrollToTop:hover {
    background-color: $gray-3; /* Set a background color */
    color: $gray-1; /* Text color */
    &:before {
        color: $gray-1;
    }
}
