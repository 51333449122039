.impressum,
.datenschutz {
    padding-top: 140px;
    .navbar.navbar--hide {
        background: $blue-1;
        .navbar__brand {
            opacity: 1;
        }
        .heart {
            display: none;
        }
    }

    & .section.frag-sonja {
        padding-top: 0;
    }

    p {
        max-width: 100%;
    }
}
